.list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-heading {
  color: blue;
  text-align: center;
}

.employee {
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin: 10px 0;
}

.btn {
  border: none;
  color: white;
  cursor: pointer;
  height: 40px;
  width: 80px;
  border-radius: 5px;
}

.delete-btn {
  background-color: red;
}

.edit-btn {
  background-color: lightblue;
}
